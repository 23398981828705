import React from 'react';
import PopupForm from 'components/client/PopupForm';

const PopupPage = () => {
  return (
    <>
      <PopupForm />
    </>
  );
};

export default PopupPage;
