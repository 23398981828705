import React from 'react';
import PopupForm from 'components/client_en/PopupForm';

const PopupEnPage = () => {
  return (
    <>
      <PopupForm />
    </>
  );
};

export default PopupEnPage;
